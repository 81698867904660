import '@usb-inner-src/react-table/dist/library/styles/index.css'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import styles from './AemPayloadError.module.scss'

export const colOptionContact = {
  span: null,
  spans: {
    xlarge: 8,
    large: 8,
    medium: 6,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 2,
    medium: 0,
    small: 0,
  },
  display: 'block',
  padding: 'zero',
  align: 'top',
  justify: 'left',
}
export default function AemPayloadError(errorData: any) {
  return (
    <div className={styles.mainContainer}>
      <USBGrid padding="normal">
        <USBColumn layoutOpts={colOptionContact}>
          <div className={styles.header}>
            <h1 data-testid="aem-payload-error">{errorData.message}</h1>
          </div>
        </USBColumn>
      </USBGrid>
    </div>
  )
}
